import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "react-bootstrap";
import AddPaymentMethodForm from "./AddPaymentMethodForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentMethodModal = ({
  show = false,
  toggle = () => {},
  redirectTo = "/account/recipient-payment-settings",
  buttonLabel = "Save Payment Method",
  intentPayload = undefined,
}) => {
  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <Elements
          stripe={stripePromise}
          options={{ mode: "setup", currency: "usd" }}
        >
          <AddPaymentMethodForm
            buttonLabel={buttonLabel}
            redirectTo={redirectTo}
            intentPayload={intentPayload}
            onCancel={() => toggle()}
          />
        </Elements>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaymentMethodModal;
