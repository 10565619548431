import React, { useState } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import get from "lodash.get";
import Delete from "@mui/icons-material/Delete";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

import { ucwords } from "../../../lib/stringHelpers";
import { useApi } from "../../../hooks/useApi";
import CreditCardForm from "../../common/CreditCardForm";

const PaymentMethods = ({ user, methods }) => {
  const [showCC, setShowCC] = useState(false);
  const queryClient = useQueryClient();

  const updateMethod = useApi({
    onSuccess: () => {
      toast.success("Your changes have been successfully saved.");
      queryClient.invalidateQueries(`admin-users-${user._id}`);
    },
    onError: (err) => toast.error(err.message),
  });

  const deleteMethod = useApi({
    onSuccess: () => {
      toast.success("Your changes have been successfully saved.");
      queryClient.invalidateQueries(`admin-users-${user._id}`);
    },
    onError: (err) => toast.error(err.message),
  });

  return (
    <>
      <h3 className="mb-4">Payment Methods</h3>
      <div>
        {methods.map((method, idx) => {
          const defaultPaymentMethod = get(
            user,
            "customer.defaultPaymentMethod",
            null
          );

          return (
            <div
              key={idx}
              className="d-flex justify-content-between align-items-start mb-3 pb-3"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div>
                <span className="me-2">
                  {ucwords(method.card.brand)} {method.card.last4}
                </span>
                {method.id === defaultPaymentMethod ? (
                  <Badge bg="secondary" size="sm">
                    Default
                  </Badge>
                ) : null}
              </div>
              <div>
                <Button
                  size="sm"
                  variant="link"
                  style={{ textDecoration: "none" }}
                  className="me-2"
                  disabled={method.id === defaultPaymentMethod}
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to make this payment method your default?"
                      )
                    ) {
                      updateMethod.call(
                        `/admin/users/${user._id}`,
                        "put",
                        undefined,
                        {
                          "customer.defaultPaymentMethod": method.id,
                        }
                      );
                    }
                  }}
                >
                  <CheckCircle />
                </Button>
                <Button
                  variant="link"
                  size="sm"
                  disabled={deleteMethod.isLoading}
                  onClick={() => {
                    if (methods.length === 1) {
                      alert(
                        "You cannot delete your only payment method. Please add a replacement and try again."
                      );
                      return;
                    }

                    if (
                      window.confirm(
                        "Are you sure you want to delete this payment method?"
                      )
                    ) {
                      deleteMethod.call(
                        `/admin/users/${user._id}/payment-methods/${method.id}`,
                        "delete"
                      );
                    }
                  }}
                >
                  <Delete />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Modal show={showCC} onHide={() => setShowCC(false)}>
        <Modal.Body>
          <CreditCardForm
            buttonLabel="Save Payment Method"
            redirectUrl="/account/recipient-payment-settings"
            onComplete={(details) => console.log("DETAILS: ", details)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentMethods;
