import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  FormGroup,
  FormCheck,
  FormControl,
  Alert,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { useUser } from "../../contexts/UserContext";
import { useApiPost } from "../../hooks/useApi";
import useHelper from "../../hooks/useHelper";
import { SubmitButton, HideOnMobile } from "../common/Registration.elements";
import { SetupTitle } from "../common/Title.elements";
import RegisterLayout from "../layout/RegisterLayout";
import { PaddedForm } from "../common/PaddedForm";

const HelperRegisterAccount = () => {
  const { helper, saveHelper } = useHelper();
  const { signIn } = useUser();
  const [formData, setFormData] = useState({
    name: helper.name || "",
    email: helper.email || "",
    mobile: helper.mobile || "",
    backgroundCheck: helper.backgroundCheck || false,
    acceptTerms: helper.acceptTerms || false,
    marketingTerms: true,
    smsTerms: true,
  });
  const [formError, setFormError] = useState(null);
  const navigate = useNavigate();

  const registerUser = useApiPost("/register/helper", (res) => {
    window.gtag("event", "conversion", {
      send_to: "AW-11127239856/NtTXCO-j7vAYELDp8Lkp",
    });
    signIn(res.token, undefined, false);
    navigate("/become-a-helper/verify");
  });

  const getPayload = () => {
    return {
      email: formData.email.trim(),
      name: formData.name.trim(),
      googleTokenId: formData.googleTokenId,
      helper: {
        serviceArea: helper.serviceArea._id,
        servicesOffered: Object.keys(helper.services).map((serviceId) => {
          return {
            service: serviceId,
            options: Object.keys(helper.services[serviceId]).map((option) => {
              return {
                key: option,
                value: helper.services[serviceId][option],
              };
            }),
          };
        }),
        specializedCare: helper.dementiaCare ? ["Dementia Care"] : [],
        jobTypes: helper.jobTypes,
        availableOvernight: helper.availableOvernight,
        hourlyRates: {
          standard: helper.standard,
          specialized: helper.specialized,
          overnight: helper.availableOvernight ? helper.overnight : null,
        },
        maxTravelDistance: helper.miles,
        vehicleAvailable: helper.vehicleAvailable,
        vehicleCovers: helper.vehicleCovers,
        vehicleInfo: null,
        notifications: {
          newHelpRequest: {
            sms: false,
            email: true,
          },
          bookingCancellation: {
            sms: false,
            email: true,
          },
          bookingReminders: {
            sms: false,
            email: true,
          },
          paymentInProgress: {
            sms: false,
            email: true,
          },
          issueWithPayment: {
            sms: false,
            email: true,
          },
        },
      },
      address: {
        postal: helper.zip,
      },
      settings: {
        timezone: "America/Chicago",
        mobile: formData.mobile || null,
        marketingTerms: {
          sms: formData.smsTerms,
          email: formData.marketingTerms,
        },
      },
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!/^[\w]+\s+[\w]+/gi.test(formData.name)) {
      setFormError(
        "Please enter your full name - at least two words required."
      );
      return;
    }

    if (formData.mobile && !/\d{3}-\d{3}-\d{4}/.test(formData.mobile)) {
      toast.error(
        "Invalid phone number format. Please check your mobile number and try again."
      );
      return;
    }

    // save email on helper for next step
    saveHelper({ email: formData.email });

    const payload = getPayload();
    registerUser.mutate(payload);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isDisabled = () => {
    if (
      !formData.backgroundCheck ||
      !formData.acceptTerms ||
      formData.name.length < 3 ||
      formData.name.indexOf(" ") === -1 ||
      formData.email.length < 3
    ) {
      return true;
    }

    if (!/^.+@.+\..+$/.test(formData.email)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <RegisterLayout
      title="To connect you with individuals, we'll need to create your Manana account."
      steps={["Location", "Services", "Business", "Account"]}
      activeStep={3}
      onStepBack={() => navigate("/become-a-helper/rates")}
    >
      <Helmet>
        <title>Register | Become A Helper | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <PaddedForm onSubmit={onSubmit}>
        <HideOnMobile>
          <Button
            type="button"
            variant="link"
            onClick={() => navigate("/become-a-helper/rates")}
            style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
          >
            <ArrowBack />
          </Button>
        </HideOnMobile>

        <SetupTitle>Create account</SetupTitle>

        {formError ? <Alert variant="danger">{formError}</Alert> : null}
        {registerUser.isError ? (
          <Alert variant="danger">{registerUser.error.message}</Alert>
        ) : null}

        <FormGroup className="mb-4">
          <FormControl
            type="text"
            name="name"
            placeholder="Full name"
            value={formData.name}
            required={true}
            onChange={onChange}
            style={{ display: "block" }}
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <FormControl
            type="email"
            name="email"
            placeholder="your@email.com"
            value={formData.email}
            required={true}
            onChange={onChange}
            style={{ display: "block" }}
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <InputMask
            type="tel"
            name="mobile"
            mask="999-999-9999"
            className="form-control"
            placeholder="123-123-1234 (optional)"
            onChange={onChange}
            value={formData.mobile}
          />
        </FormGroup>

        <p className="body1">
          To ensure the safety of our care recipients, all helpers must pass a
          background check.
        </p>

        <FormGroup className="mb-3" controlId="cb-background">
          <FormCheck
            type="checkbox"
            label="I agree to a background check."
            value={true}
            checked={formData.backgroundCheck}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "backgroundCheck", value: e.target.checked },
              })
            }
          />
        </FormGroup>

        <FormGroup className="mb-3" controlId="cb-terms">
          <FormCheck
            type="checkbox"
            label={
              <>
                I agree to all{" "}
                <Link
                  to="/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </Link>{" "}
                and acknowledge the{" "}
                <Link
                  to="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
                .
              </>
            }
            value={true}
            checked={formData.acceptTerms}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "acceptTerms", value: e.target.checked },
              })
            }
          />
        </FormGroup>

        <FormGroup className="mb-3" controlId="cb-background">
          <FormCheck
            type="checkbox"
            label="I agree to receive relevant marketing and informational emails from Manana."
            value={true}
            checked={formData.marketingTerms}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "marketingTerms", value: e.target.checked },
              })
            }
          />
        </FormGroup>

        <FormGroup className="mb-3" controlId="cb-background">
          <FormCheck
            type="checkbox"
            label={
              <>
                <span>
                  Never miss a job opportunity! Sign up for SMS, and get job
                  alerts right on your phone.{" "}
                  <i>
                    No spam, ever. Msg frequency varies. Msg & data rates apply.
                    Reply HELP for help and STOP to opt out any time. View Terms
                    &amp; Privacy.
                  </i>
                </span>
              </>
            }
            value={true}
            checked={formData.smsTerms}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "smsTerms", value: e.target.checked },
              })
            }
          />
        </FormGroup>
        <hr />

        <div className="text-end">
          <SubmitButton type="submit" disabled={isDisabled()}>
            {registerUser.isLoading ? "..." : "NEXT"}
          </SubmitButton>
        </div>
      </PaddedForm>
    </RegisterLayout>
  );
};

export default HelperRegisterAccount;
