export const isLocalEnv = () => {
  if (/api\.mananahelp\.local/.test(process.env.REACT_APP_API_URL)) {
    return true;
  }
  return false;
};

export const isDevEnv = () => {
  if (/api\.dev\.mananahelp\.com/.test(process.env.REACT_APP_API_URL)) {
    return true;
  }
  return false;
};

export const isProdEnv = () => {
  if (/api\.mananahelp\.com/.test(process.env.REACT_APP_API_URL)) {
    return true;
  }
  return false;
};

export const getCheckrBaseUrl = () => {
  return isProdEnv()
    ? "https://api.checkr.com/v1"
    : "https://api.checkr-staging.com/v1";
};

export const getCheckrBaseWebUrl = () => {
  return isProdEnv() ? "https://checkr.com/v1" : "http://checkrhq-dev.net";
};

/**
 * Data available: https://docs.google.com/spreadsheets/d/11ZeECuXArp7x3DoBXW-pz-Lgbe5uHvpCGGK1ZrdOMkU/edit#gid=0
 */

export const getPassBackgroundData = () => {
  return {
    firstName: "George",
    lastName: "Gray",
    middleName: "",
    ssn: "255-34-2255",
    dob: "01/01/1965",
    street1: "123 Main St",
    city: "Chicago",
    state: "IL",
    zip: "60644",
    dlNumber: "D50078340001",
    dlState: "IL",
    phone: "833-835-0629",
  };
};

export const getConsiderBackgroundData = () => {
  return {
    firstName: "Nick",
    lastName: "Jonas",
    middleName: "",
    ssn: "556-57-5566",
    dob: "02/10/1983",
    street1: "123 Main St",
    city: "Denver",
    state: "CO",
    zip: "80205",
    dlNumber: "Q2233344",
    dlState: "CA",
    phone: "833-835-0629",
  };
};

export const getClearWithCanceledBackgroundData = () => {
  return {
    firstName: "Alex",
    lastName: "Taylor",
    middleName: "",
    ssn: "544-21-5544",
    dob: "02/10/1983",
    street1: "123 Main St",
    city: "New York",
    state: "NY",
    zip: "10133",
    dlNumber: "A2315179",
    dlState: "CA",
    phone: "833-835-0629",
  };
};

export default isLocalEnv;
