import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Alert } from "react-bootstrap";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useCreateSetupIntent } from "../../hooks/services/usePayments";

const AddPaymentMethodForm = ({
  onCancel,
  redirectTo = "/settings/billing",
  buttonLabel = "Save Payment Method",
  intentPayload = {},
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleError = (err) => {
    setErr(err.message);
    setLoading(false);
  };

  const handleLoading = () => {
    setErr(null);
    setLoading(true);
  };

  const createSetupIntent = useCreateSetupIntent(async (res) => {
    const { clientSecret } = res;

    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}${redirectTo}`,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the setup. Show the error to your customer (for example, payment details incomplete)
      handleError(error.message);
    } else {
      setLoading(false);
    }
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!stripe) {
        return null;
      }

      handleLoading();

      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError.message);
        return;
      }

      createSetupIntent.mutate({ params: intentPayload });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      {err ? (
        <Alert variant="danger">
          <p>{err}</p>
        </Alert>
      ) : null}

      <PaymentElement />
      <hr />
      <Button
        type="submit"
        variant="primary"
        className={typeof onCancel === "function" ? "me-3" : "d-block w-100"}
        disabled={loading}
      >
        {buttonLabel}
      </Button>
      {typeof onCancel === "function" ? (
        <Button variant="link" onClick={() => onCancel()}>
          Cancel
        </Button>
      ) : null}
    </form>
  );
};

AddPaymentMethodForm.propTypes = {
  onCancel: PropTypes.func,
  redirectTo: PropTypes.string,
  intentPayload: PropTypes.any,
};

export default AddPaymentMethodForm;
