import React, { createContext, useContext, useState } from "react";
import { getCookie, setCookie, getCookieOpts } from "../lib/cookies";

const UserContext = createContext();

const getJsonItem = (key) => {
  const val = window.localStorage.getItem(key);
  if (!val) {
    return null;
  }
  return JSON.parse(val);
};

const DEFAULT_VALUES = {
  user: null,
  token: null,
  signInEmail: "",
  verificationToken: null,
};

const UserProvider = ({ children }) => {
  const user = getJsonItem("user");
  const [context, setContext] = useState({
    user,
    token: getCookie("token") || null,
    signInEmail: "",
    notifications: (user && user._notifications) || null,
    verificationToken: getCookie("verificationToken") || null,
  });

  const updateUser = (userData, callback = undefined) => {
    const updates = { ...context.user, ...userData };

    window.localStorage.setItem("user", JSON.stringify(updates));

    setContext({ ...context, user: updates });

    if (typeof callback === "function") {
      callback();
    }
  };

  const signIn = (token, callback = undefined, clearStore = true) => {
    const opts = getCookieOpts();

    if (clearStore) {
      window.localStorage.clear();
      window.sessionStorage.clear();
    }

    setCookie("verificationToken", token, opts);
    setContext({ ...context, verificationToken: token });

    if (typeof callback === "function") {
      callback();
    }
  };

  const signOut = (callback = undefined) => {
    const opts = getCookieOpts();
    opts.maxAge = 0;

    window.localStorage.clear();
    window.sessionStorage.clear();

    setCookie("token", null, opts);
    setCookie("verificationToken", null, opts);

    setContext({ ...DEFAULT_VALUES });

    if (typeof callback === "function") {
      callback();
    }
  };

  const verified = (userData, callback = undefined) => {
    const { _token, ...data } = userData;
    const opts = getCookieOpts();

    // we need to ensure the store is clear because of a change to the
    // signin method above that allows the verification stage to trigger
    // without wiping the storage - used for helper registration
    window.localStorage.clear();
    window.sessionStorage.clear();

    // now start fresh
    window.localStorage.setItem("user", JSON.stringify(data));

    setCookie("token", _token, opts);
    setCookie("verificationToken", null, { ...opts, maxAge: 0 });

    setContext({
      ...DEFAULT_VALUES,
      user: data,
      token: _token,
      notifications: data._notifications || null,
      verificationToken: null,
    });

    if (typeof callback === "function") {
      callback();
    }
  };

  const setSignInEmail = (email, callback) => {
    setContext({ ...context, signInEmail: email });
    if (typeof callback === "function") {
      callback();
    }
  };

  const value = {
    user: context.user,
    notifications: context.notifications,
    token: context.token,
    verificationToken: context.verificationToken,
    signInEmail: context.signInEmail,
    updateUser,
    signIn,
    signOut,
    verified,
    setSignInEmail,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
