import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import moment from "moment-timezone";

import InternalLayout from "../layout/InternalLayout";
import Tabs from "../common/Tabs";
import JobList from "./components/JobList";
import StripeHeaderNotice from "../common/StripeHeaderNotice";
import { useApiGet } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import { useUser } from "../../contexts/UserContext";

const Bookings = () => {
  const { user } = useUser();
  const { formData, onChange } = useFormData({
    userId: "",
    sortDir: 1,
  });
  const { loading, data } = useApiGet("past-users", `/bookings/past-users`);

  return (
    <InternalLayout>
      <Helmet>
        <title>Bookings | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>
        <h2 className="mb-5">Bookings</h2>

        <StripeHeaderNotice />

        <Row>
          <Col xs={12} sm={6} md={4}>
            <Form.Group className="mb-4">
              <Form.Label>Sort By</Form.Label>
              <Form.Select
                name="sortDir"
                value={formData.sortDir}
                onChange={onChange}
              >
                <option value={1}>Newest First</option>
                <option value={-1}>Oldest First</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            {!loading && data && data.length > 0 ? (
              <Form.Group className="mb-4">
                <Form.Label>
                  {user.userType === "helper" ? "Customers" : "Helpers"}
                </Form.Label>
                <Form.Select
                  name="userId"
                  value={formData.userId}
                  onChange={onChange}
                >
                  <option value="">All</option>
                  {data.map((u, uIdx) => {
                    return (
                      <option key={uIdx} value={u.userId}>
                        {u.userName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            ) : null}
          </Col>
        </Row>

        <Tabs
          tabs={[
            {
              label: "New Requests",
              content: (
                <JobList
                  jobStatus="New help request"
                  startDate={moment().startOf("day").format("YYYY-MM-DD")}
                  sortDir={formData.sortDir}
                  userId={formData.userId}
                />
              ),
            },
            {
              label: "Active/Upcoming",
              content: (
                <JobList
                  jobStatus="Booking Confirmed"
                  startDate={moment().startOf("day").format("YYYY-MM-DD")}
                  sortDir={formData.sortDir}
                  userId={formData.userId}
                />
              ),
            },
            {
              label: "Completed",
              content: (
                <JobList
                  jobStatus="Booking Complete"
                  sortDir={formData.sortDir}
                  userId={formData.userId}
                />
              ),
            },
            {
              label: "Canceled",
              content: (
                <JobList
                  jobStatus="Booking Canceled"
                  sortDir={formData.sortDir}
                  userId={formData.userId}
                />
              ),
            },
          ]}
        />
      </Container>
    </InternalLayout>
  );
};

export default Bookings;
