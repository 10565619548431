import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddPaymentMethodForm from "./AddPaymentMethodForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentMethodInline = ({
  redirectTo = "/account/recipient-payment-settings",
  buttonLabel = "Save Payment Method",
  intentPayload = undefined,
  additionalContent = null,
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: "setup", currency: "usd" }}
    >
      <AddPaymentMethodForm
        buttonLabel={buttonLabel}
        redirectTo={redirectTo}
        intentPayload={intentPayload}
      />
      {additionalContent}
    </Elements>
  );
};

export default AddPaymentMethodInline;
