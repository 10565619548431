import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import Notifications from "@mui/icons-material/Notifications";
import Close from "@mui/icons-material/Close";
import get from "lodash.get";

import { useApiGet, useApi } from "../../../../hooks/useApi";
import Loading from "../../../common/Loading";
import { useUser } from "../../../../contexts/UserContext";
import ProfilePicture from "../../../common/ProfilePicture";

const NotificationsWrapper = styled.div`
  position: relative;

  & > div.notifs {
    display: none;
    position: absolute;
    top: 42px;
    left: 0;
    z-index: 999;
    width: 50vw;
    max-width: 500px;
    background: #fff;
    box-shadow: 0 6px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 1rem;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: 5px;
  }

  & > div.notifs.open {
    display: block;
  }

  & div.notifs-list {
    max-height: 70vh;
    overflow-y: auto;
  }

  & div.notifs-list-item {
    border-bottom: 1px solid #ebe0de;
    margin-bottom: 1rem;
    padding-right: 1rem;
    cursor: pointer;
  }

  & div.notifs-list a {
    text-decoration: none;
  }

  & button.notif-btn {
    color: rgb(133, 112, 112) !important;
  }

  & button.notif-btn.active {
    color: rgb(253, 112, 96) !important;
  }

  & hr {
    margin: 1rem 0;
  }

  @media only screen and (max-width: 430px) {
    & > div.notifs {
      right: -10vw;
      left: auto;
      width: 80vw;
    }
  }
`;

const NotificationsBackground = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 998;

  &.open {
    display: block;
  }
`;

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const UserNotifications = () => {
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading, data } = useApiGet(
    "notifications",
    "/users/notifications"
  );

  const markOneAsRead = useApi({
    onSuccess: () => queryClient.invalidateQueries("notifications"),
    onError: (err) => toast.error(err.message),
  });

  const markAllAsRead = useApi({
    onSuccess: () => queryClient.invalidateQueries("notifications"),
    onError: (err) => toast.error(err.message),
  });

  const onClickNotification = (row) => {
    const url = new URL(row.notificationVars.url || "/dashboard");
    markOneAsRead.call(`/users/notifications/${row._id}`, "put");
    navigate(url.pathname + url.search || "");
    setOpen(false);
  };

  const onMarkAll = () => {
    markAllAsRead.call(`/users/notifications/mark-all`, "put");
    setOpen(false);
  };

  const getPicUser = (row) => {
    const vars = row.notificationVars;

    switch (true) {
      case user.userType === "helper" && typeof vars.customer !== "undefined":
        return vars.customer;
      case user.userType === "customer" && typeof vars.helper !== "undefined":
        return vars.helper;
      default:
        return user;
    }
  };

  const getTitle = (row) => {
    const vars = row.notificationVars;

    switch (row.notificationType) {
      case "helper:job:new":
        return `You have a new help request from ${vars.customer.firstName} for ${vars.date}.`;
      case "helper:job:expired":
        return `You missed out on ${vars.amount} with ${
          vars.customer ? vars.customer.firstName : vars.recipientName
        }. Confirm your notifications so you don't miss opportunities in the future.`;
      case "helper:job:canceled:customer:morethan24hours":
      case "helper:job:canceled:customer:lessthan24hours":
      case "helper:payment:failed":
        return `Your upcoming booking for ${vars.date} has been canceled by ${vars.customer.firstName}.`;
      case "helper:payment:payout":
        return `We've transferred your account balance of ${vars.amount} to your bank account.`;
      case "helper:job:review":
        return `Please share your feedback about your booking with ${vars.customer.firstName}.`;
      case "helper:background:approved":
        return `Your background check has been approved!`;

      case "customer:job:accepted":
        return `Your booking for ${vars.date} was accepted by ${vars.helper.firstName}.`;
      case "customer:job:expired":
        return `Your booking request for ${vars.date} was not accepted by any of the selected helpers.`;
      case "customer:job:canceled:helper:morethan24hours":
      case "customer:job:canceled:helper:lessthan24hours":
        return `Your upcoming booking for ${vars.date} has been canceled by ${vars.helper.firstName}.`;
      case "customer:payment:verification":
        return `Verify your payment method for an upcoming booking with ${vars.helper.firstName}.`;
      case "customer:job:review":
        return `Please share your feedback about your booking with ${vars.helper.firstName}.`;
      case "customer:payment:refund":
        return `A refund of ${vars.amount} has been successfully issued for your booking with ${vars.helper.firstName}.`;
      case "customer:payment:failed:36hours":
        return `Your payment for your booking on ${get(
          vars,
          "date",
          "upcoming day"
        )} with ${get(vars, "helper.firstName", "Unknown")} has failed.`;
      case "customer:payment:failed:24hours":
        return `Your booking on ${vars.date} with ${get(
          vars,
          "helper.firstName",
          "Unknown"
        )} was canceled due to payment failure.`;
      case "customer:payment:success":
        return `You have successfully paid ${
          vars.amount
        } for your booking with ${get(
          vars,
          "helper.firstName",
          "Unknown"
        )} on ${vars.date}.`;

      case "message:new":
        return `You have a new message from ${vars.fromName}.`;

      default:
        return row.notificationType;
    }
  };

  const renderList = () => {
    const items = [];

    if (isLoading || markAllAsRead.isLoading || markOneAsRead.isLoading) {
      return <Loading />;
    }

    if (data.total === 0) {
      return (
        <p className="text-center">You do not have any unread notifications.</p>
      );
    }

    data.rows.forEach((row, idx) => {
      const picUser = getPicUser(row);
      const title = getTitle(row);

      items.push(
        <div
          key={idx}
          className="notifs-list-item d-flex justify-content-start align-items-start"
          style={{ gap: "1rem" }}
          onClick={() => onClickNotification(row)}
        >
          <div>
            <ProfilePicture user={picUser} />
          </div>
          <div>
            <p className="mb-1" style={{ fontWeight: 500 }}>
              {title}{" "}
              <span className="text-muted">
                {capitalizeFirstLetter(moment(row.date).fromNow())}
              </span>
            </p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="text-end mb-2">
          <Button
            variant="link"
            size="sm"
            className="p-0 m-0"
            onClick={() => onMarkAll()}
          >
            Mark all as read
          </Button>
        </div>
        <div className="notifs-list">{items}</div>
      </>
    );
  };

  return (
    <>
      <NotificationsBackground
        className={open ? "open" : ""}
        onClick={() => setOpen(false)}
      />
      <NotificationsWrapper>
        <Button
          className={
            data && data.rows.length > 0 ? "notif-btn active" : "notif-btn"
          }
          variant="link"
          onClick={() => setOpen(!open)}
        >
          <Notifications />
        </Button>
        <div className={open ? "notifs open" : "notifs"}>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="mb-0">Notifications</h3>
            <Button variant="link" onClick={() => setOpen(false)}>
              <Close />
            </Button>
          </div>
          <hr />

          {renderList()}
        </div>
      </NotificationsWrapper>
    </>
  );
};

export default UserNotifications;
