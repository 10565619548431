import { useMutation } from "react-query";
import axios from "axios";
import { err, noop } from "../../lib/serviceHelpers";

export const useCreateSetupIntent = (callback, opts = {}) => {
  return useMutation({
    mutationFn: async ({ params = {} }) => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/payments/setup-intent`,
        {
          params,
        }
      );
      return res.data;
    },
    ...opts,
    onSuccess: callback || noop,
    onError: opts.onError || err,
  });
};

export const useFinalizeSetupIntent = (callback, opts = {}) => {
  return useMutation({
    mutationFn: async ({ setupIntentId, payload = {} }) => {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/payments/setup-intent/finalize`,
        {
          setupIntentId,
          ...payload,
        }
      );
      return res.data;
    },
    ...opts,
    onSuccess: callback || noop,
    onError: opts.onError || err,
  });
};
