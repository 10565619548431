import React from "react";

const NotificationsHistory = ({ user, notifications }) => {
  return (
    <>
      <h3 className="mb-4">Notification History</h3>
      <div>
        {notifications.map((notif, idx) => {
          return (
            <div
              key={idx}
              className="d-flex align-items-start mb-3 pb-3"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div style={{ flex: 1 }}>
                <p className="mb-1">
                  <strong>{notif.notificationType}</strong>
                </p>
                <p className="mb-0">{notif.date}</p>
              </div>
              <div style={{ flex: 2 }}>
                <p className="mb-1">Emails: {notif.sentToEmails.join(", ")}</p>
                <p className="mb-0">SMS: {notif.sentToSms.join(", ")}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default NotificationsHistory;
